.img-cuadrada-150 {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin: 5px;
}

/* Asegura que el iframe se ajuste al tamaño del modal */
.my-responsive-iframe {
    width: 450px; /* El iframe ocupará el 100% del ancho del modal */
    max-width: 100%; /* No permitirá que el iframe se estire más allá del modal */
    height: 400px; /* Puedes ajustar esta altura según lo que necesites */
    border: none; /* Elimina el borde por defecto del iframe */
  }

  @media (max-width: 768px) {  
    .my-responsive-iframe {
      height: 100%; /* El iframe ocupará el 100% de la altura disponible del modal */
    }
  }
  