.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh; /* 100% de la altura de la ventana del navegador */
    background-attachment: fixed; /* Fija la imagen para que no se desplace con el contenido */
    text-align: center;
    box-sizing: border-box;
  }
.img-background {
    justify-content: center;
    background-position: center;
    background-size: cover; /* Asegura que la imagen cubra toda la pantalla */
    color:white; /* Color del texto, puede ajustarse según la imagen de fondo */
  }
  
  /* Estilo del título */
 .home-title {
    font-size: 5rem;
    }
  .special-title {
    font-weight: bold;
    color: white; /* Cambia el color según tus necesidades */
      text-shadow: 
      1px 1px 0 #000, /* Sombra derecha e inferior */
      -1px -1px 0 #000, /* Sombra izquierda y superior */
      1px -1px 0 #000, /* Sombra derecha y superior */
      -1px 1px 0 #000; /* Sombra izquierda e inferior */
      font-family: 'Rajdhani', sans-serif;
      letter-spacing: 2px; 
  }
  .home-subtitle {
    font-family: 'Rajdhani', sans-serif;
    font-size: 3rem;
    font-weight: bold;
    margin: 0 0 20px 0;
  }

  #section1 {
    padding-top: 150px;
    background-color: #e6e6e6;
    color: #070606;
  }


  .noticias-container {
    padding: 20px 0;
  }
  
  .noticia-item {
    display: flex;
    align-items: start;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px; /* Más espacio entre noticias */
    margin-bottom: 20px; /* Separación entre bloques */
  }
  
  .noticia-foto-container {
    width: 40%;
    max-height: 300px;
  }
  
  .noticia-foto {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .noticia-content {
    flex: 1;
    text-align: left; /* Alineación a la izquierda */
  }
  
  .noticia-titulo-corto {
    font-size: 28px;
    font-weight: bold;
    color: #343a40;
    margin-bottom: 10px;
  }
  
  .noticia-titulo-largo {
    font-size: 20px;
    font-weight: 500;
    color: #495057;
    margin-bottom: 10px;
  }
  
  .noticia-descripcion {
    font-size: 16px;
    color: #6c757d;
    margin-bottom: 10px;
  }
  
  .noticia-url {
    font-size: 16px;
    color: #007bff;
    text-decoration: none;
  }
  
  .noticia-url:hover {
    text-decoration: underline;
  }
  
  .noticias-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Tres noticias por fila */
    gap: 20px;
    margin-top: 20px;
    min-height: 400px; /* Esto asegura que el grid siempre tenga algo de altura */
  }
  .noticia-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s;
  }
  
  .noticia-card:hover {
    transform: translateY(-5px);
  }
  
  .noticia-card-img {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
  
  .noticia-card-content {
    padding: 15px;
    text-align: left;
  }
  
  .noticia-card-titulo-corto {
    font-size: 14px;
    font-weight: bold;
    color: #666;
    text-transform: uppercase;
    margin-bottom: 8px;
    background-color: #f8f9fa;
    padding: 5px 10px;
    display: inline-block;
    border-radius: 4px;
  }
  
  .noticia-card-titulo-largo {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    line-height: 1.4;
  }
  
  .noticia-card-descripcion {
    font-size: 14px;
    color: #555;
    margin-bottom: 15px;
  }
  
  .noticia-card-link {
    display: inline-block;
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    border-radius: 4px;
  }
  
  .noticia-card-link:hover {
    background-color: #0056b3;
  }
  