.col-simple-actions {
  width: 100px;
}
.col-actions3 {
  width: 130px;
}
.col-actions4 {
  width: 160px;
}
.col-index {
  width: 15px;
}
.thead-normal {
  background-color:#b2aea3; 
  text-align: center;
  vertical-align: middle;
}