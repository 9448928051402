.col-form {
  margin-bottom: 15px;
}
.normal-form label {
  font-weight: 650;
}

.soft-labels label{
  font-weight: 500;
}
.form-container {
    max-width: 800px; /* Máximo ancho */
    margin: auto; /* Centrar horizontalmente */
}
/* Hace que el modal tenga un fondo transparente */
.transparent-modal .modal-content {
  background-color: transparent;  /* Modal transparente */
  border: none;  /* Elimina los bordes */
}

/* El div que envuelve todo después de Draggable tendrá el fondo blanco */
.modal-content-white {
  background-color: white;  /* Fondo blanco */
  border-radius: 8px;  /* Bordes redondeados (opcional) */
}
.row-label-strong label {
  font-weight: bold;
}