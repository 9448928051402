/* Botones Responsivos y Estéticos */
.button-mine {
  padding: 5px 15px;
  font-size: 1rem;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  margin: 0px 8px 0px 2px;
  text-decoration: none;
  width: fit-content;
  min-width: 90px;
  display: inline-block;
  font-weight: 500;
}
.button-a {
  padding: 5px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  margin: 2px 4px 2px 2px;
  text-decoration: none;
  width: fit-content;
  display: inline-block;
}
.btn-sm {
  padding: 2px 10px 4px 10px;
  border-radius: 7px;
  font-size: 0.8rem;
}

.button-mine:hover:not(:disabled) :not(:disabled) {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.button-mine:disabled, .button[disabled] {
  box-shadow: none; /* Sin sombra */
  cursor: default;
  opacity: 0.6; /* Opacidad reducida */
}

/* Agregado para alinear el botón a la derecha */
.button-container {
  text-align: right; /* Alinea el contenido al lado derecho */
}

/* Botón Rojo */
.btn-mine-primary {
  color: white;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.btn-mine-primary:hover:not(:disabled) {
  background-color: rgba(187, 19, 19, 0.815);
  border: 1px solid rgba(187, 19, 19, 0.815);
}

/* Outline Rojo */
.btn-mine-primary-outline {
  color: var(--primary-color);
  background-color: transparent; /* Ajusta la opacidad aquí */
  border: 1px solid var(--primary-color);
}
.btn-mine-primary-outline:hover:not(:disabled)  {
  background-color: var(--primary-color);
  color: white;
}
.btn-mine-secondary {
  color: white;
  background-color: #255957;
  border: 1px solid #255957;
}

.btn-mine-secondary:hover:not(:disabled)  {
  background-color: #153c39 ;
  color: white ;
}

.btn-mine-tertiary {
  color: white;
  background-color: #3c678d;
  border: 1px solid #3c678d;
}

.btn-mine-tertiary:hover:not(:disabled)  {
  background-color: #2d4e6b ;
  color: white ;
}

/* Outline Celeste-Azul Claro */
.btn-secondary-outline {
  color: #255957;
  background-color: transparent;
  border: 0px solid #255957;
}

.btn-secondary-outline:hover:not(:disabled)  {
  background-color: #255957;
  color: white;
}
/* Outline Celeste-Azul Claro */
.btn-success-outline {
  color: #0b944a;
  background-color: transparent;
  border: 0px solid #0b944a;
}

.btn-success-outline:hover:not(:disabled)  {
  background-color: #0b944a;
  color: white;
}

.btn-presuccess-outline {
  color: #cc5e09;
  background-color: transparent;
  border: 0px solid #cc5e09;
}

.btn-presuccess-outline:hover:not(:disabled)  {
  background-color: #cc5e09;
  color: white;
}

.btn-presuccess {
  color: #cc5e09;
  background-color: transparent;
  border: 1px solid #cc5e09;
}

.btn-presuccess:hover:not(:disabled)  {
  background-color: #cc5e09;
  color: white;;
}

/* Botón Celeste-Azul Claro */
.btn-light-blue {
  color: white;
  background-color: #0080d5;
  border: 1px solid #0080d5;
}

.btn-light-blue:hover:not(:disabled)  {
  background-color: rgb(22, 106, 162);
  border: 1px solid #2980b9;
}
.btn-light-blue-outline {
  color: #2585d8;
  background-color: transparent;
  border: 1px solid #2585d8;
}

.btn-light-blue-outline:hover:not(:disabled)  {
  background-color: #2585d8;
  border: 1px solid #2585d8;
  color: white;
}


/* Botón Cancelar */
.btn-cancel {
  color: white;
  background-color: #757575;
  border: 1px solid #757575;
}

.btn-cancel:hover:not(:disabled)  {
  background-color: #464646;
  border: 1px solid #464646;
}

/* Outline Cancelar */
.btn-cancel-outline {
  color: #464646;
  background-color: transparent;
  border: 1px solid #464646;
}

.btn-cancel-outline:hover:not(:disabled)  {
  background-color: #464646;
  color: white;
}

.btn-transparent {
  color: rgb(80, 80, 80);
  background-color: transparent;
  border: none;
}

.btn-transparent:hover:not(:disabled)  {
 border: 1px solid rgba(82, 82, 82, 0.486);
}

/* Estilos Responsivos */
@media (max-width: 768px) {
  .button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .button {
    padding: 6px 12px;
    font-size: 12px;
  }
}

.icon-button {
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  text-decoration: none;
  height: 32px;
  border: 1px;
  margin-right: 2px;
  width: fit-content;
  height: fit-content;
}

.icon-button:hover:not(:disabled)  {
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
}


.file-upload-btn {
  border: 1px solid #A0522D; /* Café */
  color: #A0522D; /* Café */
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s;
}

.file-upload-btn:hover {
  background-color: #FFA07A; /* Naranja suave */
  color: #000;
}
