.footer {
  background-color: #333;
  color: white;
  padding: 1rem;
  text-align: center;
  width: 100%;
  margin-top: auto; /* Asegura que el footer se mantenga en la parte inferior */
}
  
 
.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  margin-top: 10px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}

.footer-links a:hover {
  text-decoration: underline;
}