.container-error {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    height: 100vh;
    min-height: 600px;
    margin: auto;
    padding: 40px;
    padding-top: 50px;
    background-color: rgb(248, 248, 248);
    color: black;
  }
  
  .container-error > div {
    flex: 1;
  }
  
  .container-error > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Centrar horizontalmente */
    text-align: center; /* Centrar texto */
    padding-right: 20px;
  }
  
  .container-error img {
    max-width: 100%;
    height: auto;
  }