/* General styles for the auth container */
.auth-container {
  display: flex;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Ensures no scrollbars appear */
  
}

/* Style for the form container */
.auth-form-container {
  flex: 1; /* Takes up the remaining space */
  display: flex;
  flex-direction: column; /* Aligns content vertically */
  justify-content: center; /* Centers the form vertically */
  align-items: center; /* Centers the form horizontally */
  background-color: #d4d4d5; /* Bone color */
  padding: 2rem; /* Internal spacing */
}
/* Estilo para la capa negra semi-transparente */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Negro con 50% de opacidad */
  z-index: 1; /* Asegura que esté por encima de la imagen de fondo pero por debajo del contenido */
}

/* Style for the form */
.form-auth {
  background-color:white; /* White color for the form */
  padding: 2rem; /* Internal spacing */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  width: 90%; /* Adjusts form width */
  max-width: 450px; /* Maximum width */
  z-index: 2;
  color: black;
}

/* Style for the form */
.form-register {
  max-width: 600px; /* Maximum width */
}
/* Style for the title */
.auth-title {
  margin-bottom: 2rem; /* Space below the title */
  font-size: 1.5rem; /* Title size */
  color: #333; /* Title text color */
  font-family: 'Rajdhani', sans-serif; /* Title font */
  z-index: 2;
}

/* Media query for smaller screens */
@media (max-width: 670px) {
  .auth-image {
    display: none; /* Hides the image on smaller screens */
  }
  
  .auth-form-container {
    flex: 1; /* Takes up full width */
   }
  
  .form-auth {
    width: 100%; /* Ensures the form takes up full width */
  }
}