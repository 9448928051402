
#headerIn {
  background: linear-gradient(to right, #513723, #a37751); /* Degradado negro */
  padding: 10px 0; /* Ajusta el padding según sea necesario */
}

.navbar-container{
  padding: 10px 20px; 
}
.custom-header {
    background-color: #222222;
  }
   
    /* Estilo para el enlace del logo */
    .brand-link {
      display: flex;
      align-items: center; /* Alinea verticalmente el logo y el texto */
      text-decoration: none; /* Elimina el subrayado del enlace */
    }

    /* Estilo para el texto junto al logo */
    .brand-text {
      margin-left: 10px; /* Espacio entre el logo y el texto */
      font-family: 'Rajdhani', sans-serif;
      font-size: 2rem; /* Ajusta el tamaño de la fuente */
      font-weight: bold; /* Ajusta el peso de la fuente */
      color: white; /* Cambia el color según tus necesidades */
      text-shadow: 
      1px 1px 0 #000, /* Sombra derecha e inferior */
      -1px -1px 0 #000, /* Sombra izquierda y superior */
      1px -1px 0 #000, /* Sombra derecha y superior */
      -1px 1px 0 #000; /* Sombra izquierda e inferior */
    }

  .public-header {
    background-color: transparent;
    transition: background-color 0.3s ease;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
  }
  
  .public-header.scrolled {
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .public-header .nav-link {
    color: black;
  }
  
  .public-header.scrolled .nav-link {
    color: black;
  }

  .nav-item-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  /* Contenedor del menú desplegable */
.nav-item-usuario.dropdown {
  position: relative; /* Necesario para posicionar el menú desplegable */
}

/* Menú desplegable del usuario */
.nav-item-usuario.dropdown .dropdown-menu {
  position: absolute; /* Posiciona el menú en relación con el contenedor */
  left: 50%; /* Mueve el menú al centro horizontal del contenedor */
  transform: translateX(-50%); /* Ajusta el menú para que esté centrado exactamente */
  margin-top: 10px; /* Espacio entre el ícono y el menú */
}
  .rounded-img {
    width: 50px; /* Asegura que el ancho sea de 60px */
    height: 50px; /* Asegura que la altura sea de 60px */
    border-radius: 50%; /* Hace que los bordes sean redondos */
    object-fit: cover; /* Asegura que la imagen se ajuste bien dentro del contenedor redondo */
  }

  /* Para los links en el nav */
.bottomline_link {
  position: relative;
  display: inline-block;
  text-decoration: none;
}

.bottomline_link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: gold;
  transition: width 0.3s;
  position: absolute;
  left: 0;
  bottom: -5px;
}

.bottomline_link:hover::after {
  width: 100%;
}

/* Para los items del dropdown */
.bottomline_dropdown_item {
  position: relative;
  display: inline-block;
  text-decoration: none;
}
.bottomline_dropdown_item:hover {
  background-color: #ffe4cd;
}
.bottomline_dropdown_item::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: gold;
  transition: width 0.3s;
  position: absolute;
  left: 0;
  bottom: -5px;
}

.bottomline_dropdown_item:hover::after {
  width: 100%;
}

#menu-bar {
  background: white; /* Color de fondo para la barra de menú, ajusta según tus necesidades */
  border-top: 1px solid black; /* Opcional: Añade una línea de separación */
  padding: 0.5rem 0; /* Espaciado vertical */
 
}

#menu-bar .nav-link {
  margin: 0 1rem; /* Espaciado horizontal entre los enlaces del menú */
  color: black;
}